var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-model-table',{attrs:{"model-name":"Clients","model-title":"Clients","model-api":['models','Client','Clients'],"model-key":"clientId","headers":_vm.headers,"initialData":_vm.initialData,"hideInsertButton":"","searchable":""},scopedSlots:_vm._u([{key:"modelForm",fn:function(ref){
var data = ref.data;
var rules = ref.rules;
var isCreating = ref.isCreating;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"rules":[rules.require()],"label":"Client ID","disabled":!isCreating},model:{value:(data.clientId),callback:function ($$v) {_vm.$set(data, "clientId", $$v)},expression:"data.clientId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(data.description),callback:function ($$v) {_vm.$set(data, "description", $$v)},expression:"data.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form-pad',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mainConfigData = ref.data;
var rules = ref.rules;
return [_c('v-tabs-group',{scopedSlots:_vm._u([{key:"tabs",fn:function(){return [_c('v-tab',{key:"api"},[_vm._v("API")]),_c('v-tab',{key:"location"},[_vm._v("Location")])]},proxy:true},{key:"items",fn:function(){return [_c('v-tab-item',{key:"api",attrs:{"eager":""}},[_c('v-card',[_c('v-card-text',[_c('v-form-pad',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var configData = ref.data;
var rules = ref.rules;
return [_c('v-form-pad',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var localDeviceConfig = ref.data;
var rules = ref.rules;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Local device API URL"},model:{value:(localDeviceConfig.url),callback:function ($$v) {_vm.$set(localDeviceConfig, "url", $$v)},expression:"localDeviceConfig.url"}})],1)],1)],1)]}}],null,true),model:{value:(configData.localDevice),callback:function ($$v) {_vm.$set(configData, "localDevice", $$v)},expression:"configData.localDevice"}})]}}],null,true),model:{value:(mainConfigData.api),callback:function ($$v) {_vm.$set(mainConfigData, "api", $$v)},expression:"mainConfigData.api"}})],1)],1)],1),_c('v-tab-item',{key:"location",attrs:{"eager":""}},[_c('v-card',[_c('v-card-text',[_c('v-form-pad',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var configData = ref.data;
var rules = ref.rules;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-master-field',{attrs:{"custom-api":['models','Master','Locations','query'],"custom-api-text":"locationName","custom-api-value":"locationCode","customApiEagerLoad":"","value-object":_vm.currentLocation,"label":"สถานที่","show-code":"","clearable":""},on:{"update:valueObject":function($event){_vm.currentLocation=$event},"update:value-object":function($event){_vm.currentLocation=$event}},model:{value:(configData.locationCode),callback:function ($$v) {_vm.$set(configData, "locationCode", $$v)},expression:"configData.locationCode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":"สถานที่ย่อย","items":(_vm.currentLocation) ? _vm.currentLocation.subunits : undefined,"item-text":"subunitName","item-value":"subunitCode","clearable":""},model:{value:(data.locationSubunitCode),callback:function ($$v) {_vm.$set(data, "locationSubunitCode", $$v)},expression:"data.locationSubunitCode"}})],1)],1)],1)]}}],null,true),model:{value:(mainConfigData.location),callback:function ($$v) {_vm.$set(mainConfigData, "location", $$v)},expression:"mainConfigData.location"}})],1)],1)],1)]},proxy:true}],null,true)})]}}],null,true),model:{value:(data.configuration),callback:function ($$v) {_vm.$set(data, "configuration", $$v)},expression:"data.configuration"}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }