<template lang="html">
  <v-model-table model-name="Clients" model-title="Clients"
    :model-api="['models','Client','Clients']"
    model-key="clientId"
    :headers="headers" :initialData="initialData"
    hideInsertButton searchable
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field :rules="[rules.require()]"  v-model="data.clientId" label="Client ID" :disabled="!isCreating"></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field v-model="data.description" label="Description"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-form-pad v-model="data.configuration">
              <template v-slot="{data: mainConfigData, rules}">
                <v-tabs-group>
                  <template v-slot:tabs>
                    <v-tab key="api">API</v-tab>
                    <v-tab key="location">Location</v-tab>
                  </template>
                  <template v-slot:items>
                    <v-tab-item key="api" eager>
                      <v-card>
                        <v-card-text>
                          <v-form-pad v-model="mainConfigData.api">
                            <template v-slot="{data: configData,rules}">
                              <v-form-pad v-model="configData.localDevice">
                                <template v-slot="{data: localDeviceConfig,rules}">
                                  <v-container fluid>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-text-field v-model="localDeviceConfig.url" label="Local device API URL"></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </template>
                              </v-form-pad>
                            </template>
                          </v-form-pad>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="location" eager>
                      <v-card>
                        <v-card-text>
                          <v-form-pad v-model="mainConfigData.location">
                            <template v-slot="{data: configData,rules}">
                              <v-container fluid>
                                <v-row>
                                  <v-col cols="12" md="8">
                                    <v-master-field v-model="configData.locationCode" :custom-api="['models','Master','Locations','query']"
                                      custom-api-text="locationName" custom-api-value="locationCode"
                                      customApiEagerLoad
                                      :value-object.sync="currentLocation"
                                      label="สถานที่" show-code
                                      clearable
                                    ></v-master-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-select label="สถานที่ย่อย"
                                      :items="(currentLocation) ? currentLocation.subunits : undefined"
                                      v-model="data.locationSubunitCode"
                                      item-text="subunitName"
                                      item-value="subunitCode"
                                      clearable
                                    ></v-select>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </template>
                          </v-form-pad>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </template>
                </v-tabs-group>
              </template>
            </v-form-pad>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-model-table>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'Client ID',
        value: 'clientId',
        class: 'body-2',
        width: '200px',
      },
      {
        text: 'Description',
        value: 'description',
        class: 'body-2'
      },
      {
        text: 'Last Seen',
        value: 'lastSeen',
        class: 'body-2',
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    initialData: {
      configuration: {
        api: {
          localDevice: {},
        },
        location: {}
      }
    },
    currentLocation: undefined,
  })
}
</script>

<style lang="css" scoped>
</style>
